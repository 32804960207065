import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
function DownloadSection() {
  return (
    <ScrollAnimation
      animateIn="animate__fadeInUp" // Ensure you are using the correct class from animate.css
      animatePreScroll={true}
    >
      <div className=" bg-[#635BFF0A] overflow-x-hidden">
        <div className=" container mx-auto grid md:grid-cols-3 grid-rows-1 py-8 md:py-16 items-center justify-center">
          <ScrollAnimation
            animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
            animatePreScroll={true}
            delay={300}
          >
            <div className="md:col-span-1 row-span-1 mx-6">
              <img src="img/Download/phone.png" alt="" />
            </div>
          </ScrollAnimation>

          <div className="md:col-span-2 row-span-1 md:mx-24 my-auto font-medium text-sm md:text-lg mx-7 mt-4">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. <br />
            <div className="flex justify-center md:block">
              <button className="bg-[#635BFF] text-white px-6 py-3 rounded-md mt-4 hover:bg-[#534af1] hover:translate-y-px w-[164px] md:w-auto h-[50px]">
                Download now
              </button>
            </div>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default DownloadSection;
