import React from "react";
import SecHeader from "../../components/template/SectionHeader/SecHeader";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css";
function Currency() {
  return (
    <ScrollAnimation
      animateIn="animate__fadeInDownBig"
      animateOnce={true}
      animatePreScroll={true}
    >
      <div className=" overflow-x-hidden">
        <div className="mt-20 pt- container  mx-auto bg-[#59C4FF0F] md:h-[946px] rounded-[40px] relative pb-10 md:pd-0">
          <SecHeader title={"Currency"} />
          <div className="flex justify-center">
            <ScrollAnimation
              animateIn="animate__jackInTheBox animate__daly-2s" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={700}
            >
              <img
                src="img/logo/iphoneimg.png "
                alt="iphoneimg"
                className="mt-16 w-60 md:w-auto   "
              />
            </ScrollAnimation>
          </div>
          <div className="flex items-center absolute top-52 left-10 lg:top-44 lg:left-80 gap-x-[24px] ">
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <h1 className="  text-[16px] font-[600] leading-[20.16px] hidden md:inline">
                $USD
              </h1>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <div className="md:w-[60px] md:h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient w-8 h-8 p-1 animate__animated  animate__pulse animate__infinite">
                <img
                  src="img/countryFlags/USFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>
            </ScrollAnimation>
          </div>{" "}
          <div className="flex items-center absolute md:top-44  right-10  top-80 md:right-24   lg:right-80 gap-x-[24px]    ">
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              {" "}
              <div className="md:w-[60px] md:h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient w-8 h-8 p-1 animate__animated  animate__pulse animate__infinite">
                <img
                  src="img/countryFlags/INDFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <h1 className=" text-[16px] font-[600] leading-[20.16px] hidden md:block">
                ₹INR
              </h1>{" "}
            </ScrollAnimation>
          </div>
          <div className="md:flex items-center absolute top-80  left-32 lg:left-40  gap-x-[24px] hidden">
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <div className="w-[60px] h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient animate__animated  animate__pulse animate__infinite">
                <img
                  src="img/countryFlags/CHNFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <h1 className=" text-[16px] font-[600] leading-[20.16px]">
                ¥CNY
              </h1>
            </ScrollAnimation>
          </div>{" "}
          <div className="md:flex items-center absolute top-80 lg:right-40 right-28 gap-x-[24px] hidden">
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <div className="w-[60px] h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient animate__animated  animate__pulse animate__infinite">
                <img
                  src="img/countryFlags/UKFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <h1 className=" text-[16px] font-[600] leading-[20.16px] ">
                £GBP
              </h1>
            </ScrollAnimation>
          </div>
          <div className="flex items-center absolute md:top-1/2  md:left-28 lg:left-1/4  right-10 top-40  gap-x-[24px]">
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <div className="md:w-[60px] md:h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient w-8 h-8 p-1 animate__animated  animate__pulse animate__infinite">
                <img
                  src="img/countryFlags/ERUFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <h1 className=" text-[16px] font-[600] leading-[20.16px] hidden md:block">
                €ERU
              </h1>
            </ScrollAnimation>
          </div>
          <div className="md:flex items-center absolute  top-1/2 lg:right-1/4 right-32  gap-x-[24px] hidden">
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <div className="w-[60px] h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient animate__animated  animate__pulse animate__infinite">
                <img
                  src="img/countryFlags/JPAFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <h1 className=" text-[16px] font-[600] leading-[20.16px]">
                ¥JPY
              </h1>
            </ScrollAnimation>
          </div>
          <div className="md:flex items-center absolute top-2/3 left-14 lg:left-40 gap-x-[24px] hidden">
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <div className="w-[60px] h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient animate__animated  animate__pulse animate__infinite">
                <img
                  src="img/countryFlags/CNFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <h1 className=" text-[16px] font-[600] leading-[20.16px]">
                $CAD
              </h1>
            </ScrollAnimation>
          </div>{" "}
          <div className="flex items-center absolute left-10 top-96 md:top-2/3   md:left-3/4  gap-x-[24px]">
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              animatePreScroll={true}
              delay={500}
            >
              <div className="md:w-[60px] md:h-[60px] bg-[#FFFFFF] flex justify-center items-center border-[1px] rounded-[10px] md:shadow-custom-1 md:bg-custom-gradient w-8 h-8 p-1 animate__animated  animate__pulse animate__infinite  ">
                <img
                  src="img/countryFlags/AUSFlag.svg"
                  alt="iphoneimg"
                  className=""
                />
              </div>{" "}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInRightBig" // Ensure you are using the correct class from animate.css
              a
              animatePreScroll={true}
              delay={500}
            >
              <h1 className=" text-[16px] font-[600] leading-[20.16px] hidden md:block">
                $AUD
              </h1>
            </ScrollAnimation>
          </div>
          <ScrollAnimation
            animateIn="animate__fadeInDownBig"
            animatePreScroll={true}
          >
            <div className="text-center mt-16 mx-10 font-medium text-lg">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Currency;
