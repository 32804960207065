import React, { useState } from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import { RiSendPlane2Fill } from "react-icons/ri";

function  Aibot() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenContact, setIsOpenContact] = useState(false);

  // Open AI chat modal and close contact modal
  function openModal() {
    setIsOpen(true);
    setIsOpenContact(false); // Close contact modal if it's open
  }

  // Open contact modal and close AI chat modal
  function openModalContact() {
    setIsOpenContact(true);
    setIsOpen(false); // Close AI chat modal if it's open
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalContact() {
    setIsOpenContact(false);
  }
  return (
    <div>
      <div className="flex justify-center my-8 md:my-0 gap-x-3 md:gap-0">
        <div
          className="z-10 md:fixed right-14 bottom-6 border-[3px] border-white rounded-full hover:-translate-y-px"
          onClick={openModal}
        >
          <img
            src="/img/aibot.svg"
            className="w-[50px] h-[50px]  md:w-auto md:h-auto cursor-pointer"
            alt=""
          />
        </div>
        <div
          className="z-10 md:fixed right-36 bottom-6 border-[3px] border-white rounded-full hover:-translate-y-px"
          onClick={openModalContact}
        >
          <img
            src="/img/contact.svg"
            className="w-[50px] h-[50px] md:w-auto md:h-auto cursor-pointer"
            alt=""
          />
        </div>
      </div>

      {/* AI Chat Modal */}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName="custom-modal-overlay"
        onRequestClose={closeModal}
        className="fixed w-[342px] h-[552px] md:w-[400px] md:h-[654px] bg-white border-[1px] shadow-md shadow-[#635BFF] bottom-36 right-3  md:right-20 md:bottom-30 outline-none rounded-2xl z-20 "
        contentLabel="AI Chat Modal"
      >
        <div className="font-semibold text-2xl font-sans text-center mt-6 md:mt-8">
          Ai chat
        </div>
        <div className="flex mt-3">
          <div className="w-1/2 h-[2px] bg-gradient-to-r from-[#d9d8f1] to-[#635BFF]"></div>
          <div className="w-1/2 h-[2px] bg-gradient-to-l from-[#d9d8f1] to-[#635BFF]"></div>
        </div>
        <div
          className="absolute top-5 right-7 text-2xl bg-[#635BFF0F] rounded-full p-2 cursor-pointer text-[#635BFF]"
          onClick={closeModal}
        >
          <RxCross2 />
        </div>
        <div className="w-10/12 m-auto h-4/6 mt-3  ">
          <div className=" flex pt-6">
            <img
              src="/img/aibot.svg"
              alt=""
              className="w-[50px] h-[50px] rounded-full"
            />
            <div className="text-md px-3 py-3 mx-3 h-[60px] md:h-[50px] rounded-xl bg-[#635BFF0F] font-medium">
              My pleasure anytime.
            </div>
          </div>{" "}
          <div className="text-md px-3 py-3 w-28 mt-2 md:mt-4 ms-16  h-[50px] rounded-xl bg-[#635BFF0F] font-medium">
            See you
          </div>
          <div className="text-md px-2 py-2 w-56 md:mt-8 mt-3 ms-auto  h-[104px] rounded-xl bg-[#59C4FF0F] font-medium">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </div>
          <div className="text-md px-3 py-3 w-28 mt-2 md:mt-4 ms-auto  h-[50px] rounded-xl bg-[#59C4FF0F] font-medium">
            See you
          </div>{" "}
          <div className=" flex  lg:mt-7">
            <img
              src="/img/aibot.svg"
              alt=""
              className="w-[50px] h-[50px] rounded-full"
            />
            <div className="text-md px-5 py-3 mx-3  h-[50px] rounded-xl bg-[#635BFF0F] font-medium ">
              Ok
            </div>
          </div>
        </div>

        <div className="flex justify-around items-center border-2 border-[#5d56e7] rounded-xl w-10/12 m-auto bg-white bottom-11 mt-9">
          <input
            type="text"
            className="w-8/12 mx-3  py-3 outline-none"
            placeholder="Type for message here"
          />
          <div className="text-3xl text-[#635BFF] cursor-pointer">
            <RiSendPlane2Fill />
          </div>
        </div>
      </Modal>

      {/* Contact Modal */}
      <Modal
        overlayClassName="custom-modal-overlay"
        isOpen={modalIsOpenContact}
        onRequestClose={closeModalContact}
        className="fixed w-[342px] h-[475px] md:w-[400px] md:h-[478px]  bg-white border-[1px] shadow-md shadow-[#635BFF] bottom-40 right-2   md:right-20  md:bottom-40 outline-none rounded-2xl z-20"
        contentLabel="Contact Modal"
      >
        <div className="font-semibold text-2xl font-sans text-center mt-7">
          Contact us
        </div>
        <div
          className="absolute top-5 right-9 text-2xl bg-[#635BFF0F] rounded-full p-2 cursor-pointer text-[#635BFF]"
          onClick={closeModalContact}
        >
          <RxCross2 />
        </div>
        <div className="flex mt-4">
          <div className="w-1/2 h-[2px] bg-gradient-to-r from-[#d9d8f1] to-[#635BFF]"></div>
          <div className="w-1/2 h-[2px] bg-gradient-to-l from-[#d9d8f1] to-[#635BFF]"></div>
        </div>
        <div className="font-medium mx-7 mt-5">
          Please write your message and contact information below. we will
          respond as soon as possible.
        </div>
        <form action="" className="mt-4">
          <div className="relative  -6 w-10/12 m-auto">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <svg
                className="w-4 h-4 text-[#635BFF]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
              </svg>
            </div>
            <input
              type="text"
              id="input-group-1"
              className="border-2 border-[#635BFF] text-[#635BFF] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 outline-none"
              placeholder="Enter your email"
            />
          </div>
          <div className="flex justify-center">
            <textarea
              name="message area"
              id=""
              className="h-28 w-10/12 rounded-xl border-2 border-[#635BFF] mt-6 p-3 outline-none"
              placeholder="Type your message here!"
            ></textarea>
          </div>
          <div className="flex justify-center mt-6 ">
            <button className="text-lg font-semibold text-white w-10/12 bg-[#635BFF] py-3 rounded-xl">
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Aibot;
