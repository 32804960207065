import React, { Suspense, lazy } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import data from "./../../helper/data.json";
import "animate.css";

const Card = lazy(() => import("../../components/card/Card"));
const SecHeader = lazy(() => import("../../components/template/SectionHeader/SecHeader"));

function Features() {
  return (
    <div className="bg-[#635BFF0A] relative pb-10">
      <div className="container mx-auto">
        <Suspense fallback={<div>Loading...</div>}>
          <SecHeader title={'Key App Features'} />
        </Suspense>

        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          animatePreScroll={true}
        >
          <div className="mt-2 grid xl:grid-cols-3 justify-center items-center sm:grid-cols-2 grid-cols-1 gap-x-[24px] place-items-center">
            {data.feature.map((item, index) => (
              <ScrollAnimation
                key={index}
                animateIn="animate__fadeInUp"
                animatePreScroll={true}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <Card data={item} />
                </Suspense>
              </ScrollAnimation>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default Features;
