import React from "react";

function SecHeader({ title }) {
  return (
    <div>
      <div className="flex justify-center items-center md:gap-x-7 gap-x-3  pt-10 animate__animated animate__fadeIn animate__delay-1s">
        <div className=" md:h-[3px] h-[2px] w-[50px] md:w-[100px]  bg-gradient-to-l from-[#635BFF] to-[#FFFFFF] hover:bg-gradient-to-l  hover:from-[#635BFF] hover:to-[#635BFF] cursor-pointer"></div>
        <h1 className=" lg:text-4xl md:text-3xl sm:text-3xl text-xl font-[700] md:leading-[50.4px] leading-[25.2px]">
          {title}
        </h1>
        <div className="md:h-[3px]  h-[2px]  w-[50px] md:w-[100px]    bg-gradient-to-r from-[#635BFF] to-[#FFFFFF] hover:bg-gradient-to-l  hover:from-[#635BFF] hover:to-[#635BFF] cursor-pointer"></div>
      </div>
    </div>
  );
}

export default SecHeader;
