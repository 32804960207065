import React from "react";
import SecHeader from "../../components/template/SectionHeader/SecHeader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./reviewSection.css"; // Ensure this file contains all the necessary styles

import reviews from "../../helper/reviewData.json";
import Slide from "../../components/Slide/Slide";

const ReviewSection = () => {
  // Slider settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: true, // Uncomment if you want pagination dots
  };

  return (
    <div className="container mx-auto mt-16 relative">
      <SecHeader title="What our clients say" />

      <Slider {...settings} className="w-11/12 m-auto mb-40 mt-10">
        {reviews.reviews.map((review, id) => (
          <Slide key={id} review={review} />
        ))}
      </Slider>

      {/* Animation dots */}
      <div className="absolute w-[16px] h-[16px] bg-[#59C4FF] rounded-full md:top-36 md:left-40 hidden md:block animate__animated animate__flash animate__infinite animate__slow"></div>
      <div className="absolute w-[10px] h-[10px] bg-[#635BFF] rounded-full top-40 md:left-2/3 hidden md:block animate__animated animate__flash animate__infinite animate__slow"></div>
      <div className="absolute w-[16px] h-[16px] bg-[#59C4FF] rounded-full top-80 md:right-72 hidden md:block animate__animated animate__flash animate__infinite animate__slower"></div>
      <div className="absolute w-[20px] h-[20px] bg-[#635BFF] rounded-full top-96 md:left-80 hidden md:block animate__animated animate__flash animate__infinite animate__slower"></div>
      <div className="absolute w-[10px] h-[10px] bg-[#59C4FF] rounded-full bottom-36 md:left-60 hidden md:block animate__animated animate__flash animate__infinite animate__slower"></div>
      <div className="absolute w-[6px] h-[6px] bg-[#635BFF] rounded-full bottom-40 md:left-2/3 hidden md:block animate__animated animate__flash animate__infinite animate__slow"></div>

      {/* Decorative images */}
      <div className="absolute top-[35rem] right-40 sm:right-56 md:bottom-28 md:right-44">
        <img
          src="img/review/design1.png"
          className="md:w-auto sm:w-20 w-14"
          alt="Decorative comma"
        />
      </div>
      <div className="absolute top-[21rem] left-40 sm:left-56 md:top-1/2 rotate-180">
        <img
          src="img/review/design1.png"
          className="md:w-auto sm:w-20 w-14"
          alt="Decorative comma"
        />
      </div>
    </div>
  );
};

export default ReviewSection;
