import React from "react";
import { IoStar } from "react-icons/io5";

export default function Slide({ review }) {
 

  // Generate star icons based on rating
  const stars = Array.from({ length: review.rating }, (_, index) => (
   <div className="hover:text-[#2f2b85] hover:scale-110 cursor-pointer"> <IoStar key={index} /></div>
  ));

  return (
    <div>
      <div className="flex justify-center items-center  ">
        <img className=" rounded-full w-28 sm:w-40 md:w-auto" src={review.imgUrl} alt="Reviewer" />
      </div>
      <div className="text-center text-2xl font-bold mt-6">{review.name}</div>
      <div
        className="flex justify-center text-3xl font-semibold gap-x-5 text-[#635BFF] mt-6 "
      >
        {stars}
      </div>
      <div className="text-center text-base  md:text-2xl font-bold  lg:mx-56 mt-16  ">
        {review.review}— {review.name}
      </div>
    </div>
  );
}
