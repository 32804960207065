import React from "react";
import Logo from "../template/Logo";

function Header() {
  return (
    <div className="bg-[#635BFF0F] md:bg-white">
      <div className="flex container mx-auto px-4 md:px-8 py-4 md:py-6 justify-between items-center">
        <div className="flex items-center">
          <Logo
            logoWidth="100%"
            className="h-auto max-w-[108px] md:max-w-[150px] lg:max-w-[202.5px] cursor-pointer"
          />
        </div>
        <div>
          {/* Placeholder for additional elements */}
          {/* <img src="img/logo/rigth-toggel.svg" className="w-[40px]" alt="" /> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
