import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Banner from "../banner/Banner";
import Features from "../features/Features";
import Currency from "../currencySection/Currency";
import ReviewSection from "../reviewSection/ReviewSection";
import DownloadSection from "../downloadSection/DownloadSection";
import Footer from "../../components/footer/Footer";
import Aibot from "../Aibot/Aibot";

function Home() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="h-[933px]">
        <Header />
        <Banner />
      </div>
      <Features/>
      <Currency/>
      <ReviewSection/>
      <DownloadSection/>
      <Aibot/>
      <Footer/>
    </>
  );
}

export default Home;
